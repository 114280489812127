import React, { Component } from "react";

import { faFacebookF, faTelegram } from "@fortawesome/free-brands-svg-icons";
import {
  faPhoneVolume,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as ContactGuy } from "../../assets/images/home/contact-guy-edited.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { popOutFunction } from "../../utility/utility";

class ContactPage extends Component {
  myRef = React.createRef();

  componentDidMount() {
    popOutFunction(this.myRef.current);
  }

  onCopyTextHandler = (textValue, e) => {
    let textArea = document.createElement("textarea");
    textArea.value = textValue;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (err) {
      alert("Oops, unable to copy");
    }

    document.body.removeChild(textArea);
  };

  render() {
    return (
      <section className="main-contacts" id="contacts">
        <div className="content-wrapper">
          <div className="main-headline">
            <h1 className="headline">
              Want <br />
              to talk?
            </h1>
          </div>

          <div className="contacts-container fade-in" ref={this.myRef}>
            <h2>
              Lets talk ! <hr />
            </h2>
            <ContactGuy className="img" />
            <div className="contacts">
              <div className="contact-phone">
                <a href="tel: +359886878677" className="contact-element">
                  <FontAwesomeIcon icon={faPhoneVolume} />
                  +359886878677
                </a>

                <FontAwesomeIcon
                  className="copy-icon"
                  icon={faCopy}
                  onClick={(e) => this.onCopyTextHandler("+359886878677")}
                />
              </div>
              <div className="contact-email">
                <a
                  href="mailto: office@commasense.tech"
                  className="contact-element"
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                  office@commasense.tech
                </a>

                <FontAwesomeIcon
                  icon={faCopy}
                  onClick={(e) =>
                    this.onCopyTextHandler("office@commasense.tech")
                  }
                  className="copy-icon"
                />
              </div>

              <div className="contact-social">
                <a
                  className="contact-element-social"
                  href="https://www.facebook.com/CommasenseTech/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>

                <a
                  className="contact-element-social"
                  href="https://t.me/joinchat/AAAAAFZf8rPnru55EqDcmQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTelegram} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactPage;
