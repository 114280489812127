import React, { Component } from "react";

import { ReactComponent as Development } from "../../assets/images/home/development.svg";
import { ReactComponent as PaintBrushGirl } from "../../assets/images/home/paintbrush.svg";
import { ReactComponent as Educational } from "../../assets/images/home/educational.svg";

import Card from "../Card";

class ServicesPage extends Component {
  state = {
    isFliped: false,
  };

  onFlipHandler = (clickedCard) => {
    const developmentCard = document.querySelector(".development-card");
    const designCard = document.querySelector(".design-card");

    if (clickedCard === "devCard") {
      developmentCard.classList.toggle("flip");
    } else if (clickedCard === "designCard") {
      designCard.classList.toggle("flip");
    }
  };

  render() {
    return (
      <section className="main-services" id="services">
        <div className="content-wrapper">
          <div className="main-headline">
            <h1 className="headline">
              Our <br />
              Services
            </h1>
          </div>

          <div className="services-container fade-in">
            <Card
              cardHeader="Education"
              cardImg={<Educational />}
              sideParagraphs={[
                {
                  title: "Technical Skills",
                  description:
                    "We help developers achieve a higher level of technical and organisational skills.",
                },
                {
                  title: "Employee Development",
                  description:
                    "We help companies develop existing talent while adequitely handling onboarding of new developers. Our program aims to quickly and efficiently integrate new members into the company workflow.",
                },
                {
                  title: "Fine-tuned",
                  description:
                    "The cirriculum is tailored to your company's needs. This ensures the best efficiency and viability of the developed skills.",
                },
              ]}
              linkButtonProps={{ link: "/contacts", text: "Need a Teacher?" }}
            />
            <Card
              cardHeader="Development"
              cardImg={<Development />}
              sideParagraphs={[
                {
                  title: "Web Development",
                  description:
                    " We provide back-end and front-end development to yourneeds.",
                },
                {
                  title: "Hybrid Apps Development",
                  description:
                    "Effective solutions for iOS and Android platforms with focus on performance.",
                },
                {
                  title: "Project Management",
                  description:
                    "Quality-driven web development according latest technology standards.",
                },
              ]}
              linkButtonProps={{ link: "/contacts", text: "Need a Developer?" }}
            />
            <Card
              cardHeader="Design"
              cardImg={<PaintBrushGirl />}
              sideParagraphs={[
                {
                  title: "Websites Design",
                  description:
                    "We create comprehensive and sophisticated yet simple to use interfaces for your web app.",
                },
                {
                  title: "Mobile Apps Design",
                  description:
                    "We create native mobile apps with a focus on natural gestures and interactions with the user.",
                },
                {
                  title: "UX/UI Design",
                  description:
                    " We design the flow of a multi-screen app, interactions and animations, to make interface feel amazing.",
                },
              ]}
              linkButtonProps={{ link: "/contacts", text: "Need a Designer?" }}
            />
          </div>
        </div>
      </section>
    );
  }
}
export default ServicesPage;
