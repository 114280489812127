import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Gerard from "../../assets/images/recent-projects/gerard.png";
import Lintel from "../../assets/images/recent-projects/lintel-home.jpg";
import JPM from "../../assets/images/recent-projects/jp-morgan.png";
import Mondolux from "../../assets/images/recent-projects/mondolux-home.jpg";
import HotelGallery37 from "../../assets/images/recent-projects/hotelGallery37.png";

import { popOutFunction } from "../../utility/utility";

const SampleNextArrow = ({ className, onClick }) => {
  return (
    <FontAwesomeIcon
      icon={faArrowRight}
      className={className}
      style={{ display: "block" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = ({ className, onClick }) => {
  return (
    <FontAwesomeIcon
      icon={faArrowLeft}
      className={className}
      style={{ display: "block" }}
      onClick={onClick}
    />
  );
};

class RecentProjects extends Component {
  static carouselConfig = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  myRef = React.createRef();

  componentDidMount() {
    popOutFunction(this.myRef.current);
  }

  render() {
    return (
      <section className="main-recent_projects" id="recent-projects">
        <div className="content-wrapper">
          <div className="main-headline">
            <h1 className="headline">
              Recent <br />
              Projects
            </h1>
          </div>

          <div className="recent_projects-container fade-in" ref={this.myRef}>
            <Slider {...RecentProjects.carouselConfig} className="works-slider">
              <div className="slide-content">
                <div className="slide-headline">
                  <h1>Hotel Gallery 37</h1>
                  <hr />
                </div>
                <div className="slide-img_comment">
                  <img src={HotelGallery37} alt="logo" />
                  <div className="comment">
                    <p>
                      We developed the website, including the design and an
                      reservation application, which is interactive and
                      intuative to handle.
                    </p>
                    <a
                      className="slide-visit"
                      href="https://hotelgallery37.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Check it out!
                    </a>
                  </div>
                </div>
              </div>

              <div className="slide-content">
                <div className="slide-headline">
                  <h1>Gerard</h1>
                  <hr />
                </div>
                <div className="slide-img_comment">
                  <img src={Gerard} alt="logo" />
                  <div className="comment">
                    <p>
                      Developed the front-end for the company website of the
                      german computer consulting firm Gerard Office
                      Communications GmbH.
                    </p>
                    <a
                      className="slide-visit"
                      href="https://www.gerard.de/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Check it out!
                    </a>
                  </div>
                </div>
              </div>

              <div className="slide-content">
                <div className="slide-headline">
                  <h1>Lintel</h1>
                  <hr />
                </div>
                <div className="slide-img_comment">
                  <img src={Lintel} alt="logo" />
                  <div className="comment">
                    <p>
                      A concrete works company from Germany with a great
                      reputation and tight deadlines. We developed the entire
                      front-end side of their website.
                    </p>
                    <a
                      className="slide-visit"
                      href="https://www.lintel-gruppe.de/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Check it out!
                    </a>
                  </div>
                </div>
              </div>

              <div className="slide-content">
                <div className="slide-headline">
                  <h1>JPMorgan</h1>
                  <hr />
                </div>
                <div className="slide-img_comment">
                  <img src={JPM} alt="logo" />
                  <div className="comment">
                    <p>
                      We work with JPM to create complex internal applications
                      using React.js to manage assets, funds, securities and all
                      kinds of other entities related to their business.
                    </p>
                  </div>
                </div>
              </div>

              <div className="slide-content">
                <div className="slide-headline">
                  <h1>Mondolux</h1>
                  <hr />
                </div>
                <div className="slide-img_comment">
                  <img src={Mondolux} alt="logo" />
                  <div className="comment">
                    <p>
                      The largest lighting systems provider in Australia. We had
                      to develop most of the front-end templates, styles, and
                      scripts including implementations with different external
                      APIs.
                    </p>
                    <a
                      className="slide-visit"
                      href="https://www.mondolux.com.au/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Check it out!
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}

export default RecentProjects;
