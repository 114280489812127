import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import IsVerticalContext from "../utility/isVerticalContext";
import { navClickHandler, popOutFunction } from "../utility/utility";

class Card extends Component {
  myRef = React.createRef();
  static contextType = IsVerticalContext;

  componentDidMount() {
    popOutFunction(this.myRef.current);
  }

  render() {
    const {
      cardHeader,
      cardImg,
      sideParagraphs,
      linkButtonProps: { text, link },
    } = this.props;

    const isVerticalScroll = this.context;

    return (
      //card
      <div className="card" ref={this.myRef}>
        <h2>
          {cardHeader}
          <hr />
        </h2>

        <div className="card-content">
          <div className="content-img ">{cardImg}</div>
          <div className="content-description">
            {sideParagraphs.map(({ title, description }) => {
              return (
                <div className="description-item" key={title}>
                  <h3>{title}</h3>

                  <p>{description}</p>
                </div>
              );
            })}
          </div>
        </div>
        <NavLink
          className="contact-button"
          to={link}
          onClick={() => navClickHandler(link, isVerticalScroll)}
        >
          {text}
        </NavLink>
      </div>
    );
  }
}

export default Card;
