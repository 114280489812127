export const popOutFunction = (element) => {
  const widthBoundry = 1024;
  let isVerticalScroll = window.innerWidth <= widthBoundry;

  if (!isVerticalScroll) {
    element.addEventListener("mousemove", (e) => {
      e.stopPropagation();
      const mousePosX = e.clientX - element.getBoundingClientRect().x;
      const mousePosY = e.clientY - element.getBoundingClientRect().y;

      const xAxis = -(element.clientWidth / 2 - mousePosX) / 40;
      const yAxis = (element.clientHeight / 2 - mousePosY) / 40;

      element.style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`;
      element.style.transition = "none";
      element.classList.add("popOut");
    });

    element.addEventListener("mouseenter", () => {
      setTimeout(() => {
        element.style.transition = "transform 0.1 ease";
      });
    });

    element.addEventListener("mouseleave", () => {
      setTimeout(() => {
        element.classList.remove("popOut");
        element.style.transition = "all 0.5s ease";
        element.style.transform = `rotateY(0deg) rotateX(0deg)`;
      }, 200);
    });
  }
};

export const navClickHandler = (itemTo, isVerticalScroll) => {
  if (isVerticalScroll) {
    const elementToGo = itemTo.replace("/", "#");
    const headerHeight = document.querySelector("header").offsetHeight;

    window.scrollTo(
      0,
      document.querySelector(`${elementToGo}`).offsetTop - headerHeight
    );
  }
  return;
};
