import React, { Component } from "react";
import HamburgerMenu from "./HamburgerMenu";

import { navClickHandler } from "../../../utility/utility";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faHome,
  faPhone,
  faProjectDiagram
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as LogoText } from "../../../assets/images/logo/comma-logo.svg";
import { NavLink } from "react-router-dom";

class Header extends Component {
  state = {
    menuOpen: false,
    mouseLeft: true,
  };

  handelMenuClick = () => {
    this.setState({ menuOpen: !this.state.menuOpen }, () => {
      const headerSection = document.querySelector(".header-section");

      headerSection.classList.toggle("open");
    });
  };

  componentDidMount() {
    const logoEl = document.querySelector(".logo");
    logoEl.classList.add("initial");

    setTimeout(() => {
      logoEl.classList.remove("initial");
    }, 2000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isVerticalScroll === this.props.isVerticalScroll) {
      !this.props.isVerticalScroll && this.logoAnimationHandler();
    }
  }

  logoAnimationHandler = () => {
    const headerLogoEl = document.querySelector(".header-logo");
    const polygonEl = document.querySelector(".cls-1");
    let mouseLeft = false;

    headerLogoEl.addEventListener("mouseenter", (e) => {
      headerLogoEl.classList.add("animate");
      mouseLeft = false;
    });

    headerLogoEl.addEventListener("mouseleave", () => {
      mouseLeft = true;

      polygonEl.onanimationiteration = () => {
        mouseLeft && headerLogoEl.classList.remove("animate");
      };
    });
  };

  render() {
    const { menuOpen } = this.state;

    const navItems = [
      { to: "/home", icon: faHome },
      {
        to: "/recent-projects",
        icon: faProjectDiagram,
      },
      {
        to: "/services",
        icon: faCogs,
      },
      {
        to: "/contacts",
        icon: faPhone,
      },
    ];

    return (
      <div className="header-section">
        <header className="header-container">
          <div className="header-logo">
            <NavLink
              to="/home"
              onClick={() => {
                this.setState({ menuOpen: false }, () => {
                  const headerSection = document.querySelector(
                    ".header-section"
                  );
                  headerSection.classList.remove("open");

                  navClickHandler("/home", this.props.isVerticalScroll);
                });
              }}
            >
              <LogoText className="logo" />
            </NavLink>
          </div>

          <div className="header-right-side">
            {navItems.map((item) => {
              return (
                <NavLink
                  key={item.to}
                  className="header-contacts"
                  to={item.to}
                  onClick={() => {
                    navClickHandler(item.to, this.props.isVerticalScroll);
                  }}
                >
                  <FontAwesomeIcon icon={item.icon} />
                  {item.to
                    .substr(1)
                    .charAt(0)
                    .toUpperCase() + item.to.slice(2)}
                </NavLink>
              );
            })}

            <div
              className="header-hamburger-menu"
              onClick={this.handelMenuClick}
            >
              <HamburgerMenu menuOpen={menuOpen} />
            </div>
          </div>
        </header>

        <div className="side-menu">
          {navItems.map((item) => {
            return (
              <NavLink
                key={item.to}
                className="side-menu_items"
                to={item.to}
                onClick={() => {
                  navClickHandler(item.to, this.props.isVerticalScroll);
                  this.handelMenuClick();
                }}
              >
                {item.to}
              </NavLink>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Header;
