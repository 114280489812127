import React from 'react';

const hamburgerMenu = (props) => {
  let className = '';

  if (props.menuOpen) {
    className = 'change';
  }

  return (
    <div className={props.menuOpen ? 'hamburger is-active' : 'hamburger'}>
      <div className={`bar1 ${className}`} />
      <div className={`bar2 ${className}`} />
      <div className={`bar3 ${className}`} />
    </div>
  );
};

export default hamburgerMenu;
