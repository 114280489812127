import React, { Component } from "react";

import _ from "lodash";

import Header from "../common/header/Header";

import HomePage from "./HomePage";
import RecentProjects from "./RecentProjectsPage";
import ServicesPage from "./ServicesPage";
import ContactPage from "./ContactPage";
import { IsVerticalProvider } from "../../utility/isVerticalContext";

class MainPage extends Component {
  state = {
    bodyElement: document.querySelector("body"),
    allSections: [],
    onScrollElement: null,
    allowScrolling: true,
    sectionIndex: 0,
    elementInView: null,
    isVerticalScroll: false,
    initialLoad: true,
    isScrolling: false,
  };

  componentDidMount() {
    const { sectionIndex } = this.state;
    const widthBoundry = 1024;
    const allSections = Array.from(document.querySelectorAll("section"));
    const bodyElement = document.querySelector("body");
    const isVerticalScroll = window.innerWidth <= widthBoundry;

    bodyElement.style.left = "0px";

    this.setState(
      {
        bodyElement: bodyElement,
        allSections: allSections,
        elementInView: allSections[sectionIndex],
        isVerticalScroll: isVerticalScroll,
      },
      () => {
        if (isVerticalScroll) {
          window.addEventListener("scroll", this.scrollingHandler);

          setTimeout(() => {
            this.mobileRouteHandler();
            this.setState({ initialLoad: false });
          }, 500);
        } else {
          window.addEventListener("wheel", this.wheelMovingHandler, {
            passive: false,
          });

          window.addEventListener("keydown", this.onKeyUpHandler);
          window.addEventListener("resize", this.resizeHandler);
          window.addEventListener("transitionend", this.transitionEndHandler);

          // popOutFunction(this.state.elementInView.lastElementChild);
          this.routeHandler();
        }
      }
    );
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.isVerticalScroll) {
      //MOBILE
      const urlPath = this.props.location.pathname.substring(1);
      if (
        this.state.elementInView !== prevState.elementInView &&
        urlPath !== this.state.elementInView.id &&
        !this.state.initialLoad
      ) {
        this.updateUrlPath(this.state.elementInView);
      }
    } else {
      //DESKTOP

      if (
        this.state.elementInView !== prevState.elementInView &&
        prevState.allowScrolling !== this.state.allowScrolling
      ) {
        this.updateUrlPath(this.state.elementInView);
        // popOutFunction(this.state.elementInView.lastElementChild);
      }

      if (
        prevProps.location.pathname !== this.props.location.pathname &&
        this.state.allSections.length > 0
      ) {
        this.routeHandler();
      }
    }
  };

  onKeyUpHandler = (e) => {
    e.preventDefault();

    this.shiftingHandler(e);
  };

  mobileRouteHandler = () => {
    const urlPath = this.props.location.pathname;
    const elementToGo = urlPath.replace("/", "#");
    const headerHeight = document.querySelector("header").offsetHeight;

    if (elementToGo === "#contacts") {
      document.querySelector(`${elementToGo}`).scrollIntoView();
    } else {
      window.scrollTo(
        0,
        document.querySelector(`${elementToGo}`).offsetTop - headerHeight
      );
    }
  };

  scrollingHandler = _.debounce(() => {
    const { allSections } = this.state;

    allSections.forEach((el) => {
      const rect = el.firstChild.getBoundingClientRect();
      const isInView =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth);

      if (isInView) {
        this.setState({ elementInView: el });
      }
    });
  }, 200);

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
    window.removeEventListener("transitionend", this.transitionEndHandler);
    window.removeEventListener("wheel", this.wheelMovingHandler);
  }

  updateUrlPath = (currentElementInView) => {
    this.props.history.replace(currentElementInView.id);
  };

  resizeHandler = () => {
    const { allSections, sectionIndex } = this.state;
    const sectionInView = allSections[sectionIndex];

    document.querySelector("body").style.left = ` -${
      sectionInView.offsetLeft
    }px`;
  };

  transitionEndHandler = (e) => {
    if (e.propertyName === "left") {
      this.setState({ allowScrolling: true });
    }
  };

  shiftingHandler = (event) => {
    event.preventDefault();

    const {
      bodyElement,
      allowScrolling,
      allSections,
      elementInView,
    } = this.state;

    const firstSection = document.querySelector(".main").firstElementChild;
    const lastSection = document.querySelector(".main").lastElementChild;
    const scrollLeft = event.wheelDelta > 0 || event.deltaY < 0;
    const scrollRight = event.wheelDelta < 0 || event.deltaY > 0;
    const scrollingTowardsBoundaryLeft =
      scrollLeft && firstSection === elementInView;

    const scrollingTowardsBoundaryRight =
      scrollRight && lastSection === elementInView;

    const initialPosition = elementInView.offsetLeft;

    if (event.type === "keydown") {
      const rightKey = 39;
      const leftKey = 37;

      if (event.keyCode === rightKey && allowScrolling) {
        if (!(lastSection === elementInView)) {
          const newIndex = this.state.sectionIndex + 1;
          bodyElement.style.left = `-${initialPosition +
            elementInView.clientWidth}px`;

          this.setState({
            sectionIndex: newIndex,
            allowScrolling: false,
            elementInView: allSections[newIndex],
          });
        }
      } else if (event.keyCode === leftKey && allowScrolling) {
        if (!(firstSection === elementInView)) {
          const newIndex = this.state.sectionIndex - 1;

          bodyElement.style.left = `${parseInt(bodyElement.style.left) +
            elementInView.clientWidth}px`;

          this.setState({
            sectionIndex: newIndex,
            allowScrolling: false,
            elementInView: allSections[newIndex],
          });
        }
      }
    } else {
      if (scrollRight && allowScrolling) {
        if (!scrollingTowardsBoundaryRight) {
          const newIndex = this.state.sectionIndex + 1;
          bodyElement.style.left = `-${initialPosition +
            elementInView.clientWidth}px`;

          this.setState({
            sectionIndex: newIndex,
            allowScrolling: false,
            elementInView: allSections[newIndex],
          });
        }
      } else if (scrollLeft && allowScrolling) {
        if (!scrollingTowardsBoundaryLeft) {
          const newIndex = this.state.sectionIndex - 1;

          bodyElement.style.left = `${parseInt(bodyElement.style.left) +
            elementInView.clientWidth}px`;

          this.setState({
            sectionIndex: newIndex,
            allowScrolling: false,
            elementInView: allSections[newIndex],
          });
        }
      }
    }
  };

  wheelMovingHandler = _.debounce(
    (event) => {
      const { elementInView } = this.state;

      const servicesSection = document.querySelector("#services");
      const isScrolledToTop =
        event.deltaY < 0 && servicesSection.scrollTop === 0;
      const isScrolledToBottom =
        event.deltaY > 0 &&
        Math.round(servicesSection.scrollHeight - servicesSection.scrollTop) ===
          servicesSection.clientHeight;

      if (elementInView === servicesSection) {
        if (isScrolledToTop || isScrolledToBottom) {
          this.shiftingHandler(event);
        }
      } else {
        this.shiftingHandler(event);
      }
    },
    50,
    { leading: true, trailing: false }
  );

  routeHandler = () => {
    const { allSections, bodyElement } = this.state;

    const urlPath = this.props.location.pathname;

    switch (urlPath) {
      case "/home":
        if (
          !(+bodyElement.style.left.slice(0, 1) === allSections[0].offsetLeft)
        ) {
          this.setState(
            {
              sectionIndex: 0,
              elementInView: allSections[0],
              allowScrolling: false,
            },
            () => {
              bodyElement.style.left = `${
                allSections[this.state.sectionIndex].offsetLeft
              }px`;
            }
          );
        }

        break;
      case "/recent-projects":
        this.setState(
          {
            sectionIndex: 1,
            elementInView: allSections[1],
            allowScrolling: false,
          },
          () => {
            bodyElement.style.left = `-${
              allSections[this.state.sectionIndex].offsetLeft
            }px`;
          }
        );

        break;

      case "/services":
        this.setState(
          {
            sectionIndex: 2,
            elementInView: allSections[2],
            allowScrolling: false,
          },
          () => {
            bodyElement.style.left = `-${
              allSections[this.state.sectionIndex].offsetLeft
            }px`;
          }
        );

        break;

      case "/contacts":
        this.setState(
          {
            sectionIndex: 3,
            elementInView: allSections[3],
            allowScrolling: false,
          },
          () => {
            bodyElement.style.left = `-${
              allSections[this.state.sectionIndex].offsetLeft
            }px`;
          }
        );

        break;

      default:
        this.setState(
          {
            sectionIndex: 0,
            elementInView: allSections[0],
            allowScrolling: false,
          },
          () => {
            bodyElement.style.left = `${
              allSections[this.state.sectionIndex].offsetLeft
            }px`;
          }
        );
    }
  };

  render() {
    const { elementInView, allowScrolling, isVerticalScroll } = this.state;

    return (
      <IsVerticalProvider value={isVerticalScroll}>
        <Header
          elementInView={elementInView}
          isVisible={allowScrolling}
          isVerticalScroll={isVerticalScroll}
        />
        <div className="main">
          <HomePage />
          <RecentProjects />
          <ServicesPage />
          <ContactPage />
        </div>
      </IsVerticalProvider>
    );
  }
}

export default MainPage;
