import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { navClickHandler, popOutFunction } from "../../utility/utility";

import { ReactComponent as GuyOnChair } from "../../assets/images/home/guy-on-chair.svg";
import IsVerticalContext from "../../utility/isVerticalContext";

class HomePage extends Component {
  myRef = React.createRef();
  static contextType = IsVerticalContext;

  componentDidMount() {
    popOutFunction(this.myRef.current);
  }

  render() {
    const isVerticalScroll = this.context;

    return (
      <>
        <section className="main-container" id="home">
          <div className="content-wrapper">
            <div className="main-headline">
              <h1 className="headline">Welcome</h1>
            </div>
            <div className="main-intro fade-in" ref={this.myRef}>
              <div className="main-intro-img_container">
                <GuyOnChair className="intro-img" />
              </div>
              <div className="main-intro-h2_container">
                <h2>
                  “Any fool can write code that a computer can understand. Good
                  programmers write code that humans can understand“.
                </h2>
                <NavLink
                  to="/recent-projects"
                  onClick={() =>
                    navClickHandler("/recent-projects", isVerticalScroll)
                  }
                >
                  Recent Projects
                </NavLink>
                <NavLink
                  to="/services"
                  onClick={() => navClickHandler("/services", isVerticalScroll)}
                >
                  Services
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default HomePage;
