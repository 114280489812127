import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { withRouter } from "react-router-dom";

import MainPage from "./app/components/pages/MainPage";

import "./newIndex.scss";

class App extends Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path="/:section(home|recent-projects|services|team|contacts)"
            component={MainPage}
          />
          <Redirect to="/home" />
        </Switch>
      </>
    );
  }
}

export default withRouter(App);
